import React from 'react';
import { Typography, Button, Box, Container } from '@mui/material';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined'; // An example icon that could represent teamwork or services

const HowWeWorkComponent = () => {
    return (
        <Container maxWidth="xxl" sx={{
            backgroundColor: '#112152', // Dark blue background
            color: '#ffffff', // White text for contrast
            padding: { xs: '20px 30px', sm: '30px 45px', md: '40px 60px' }, // Responsive padding
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' }, // Stack elements vertically on small screens, row on medium and up
            alignItems: 'center', // Align items vertically
            justifyContent: 'center', // Center the content
            marginTop: 4,
            marginBottom: 4,
            gap: { xs: 2, md: 0 } // Vertical gap on small screens
        }}>
            <Box sx={{ maxWidth: { xs: '100%', md: '50%' }, textAlign: { xs: 'center', md: 'left' } }}> 
                <Typography variant="h4" component="h2" sx={{
                    fontWeight: 'bold',
                    marginBottom: 2,
                    color: '#ffc107',
                    fontSize: { xs: '1.5rem', sm: '1.75rem', md: '2rem' } // Responsive font size
                }}> 
                    How we work to get our clients money back
                </Typography>
                <Typography variant="body1" sx={{ marginBottom: 3, fontSize: { xs: '0.875rem', md: '1rem' } }}>
                    First, our team of financial experts secures the relevant information from you, the victim of the scam. Then our recovery specialists and legal department track down and confront the scammers.
                </Typography>
                <Typography variant="body1" sx={{ marginBottom: 3, fontSize: { xs: '0.875rem', md: '1rem' } }}>
                    Our years of knowledge of the field make us ideally placed to fight, and win your case. You are only a short conversation away from starting the process to secure your stolen assets.
                </Typography>
                <Button variant="contained" sx={{
                    backgroundColor: '#ffc107',
                    '&:hover': { backgroundColor: '#b28900' },
                    padding: '8px 24px' // Slightly smaller padding for the button on mobile
                }}>
                    Get a free consultation
                </Button>
            </Box>
            <PeopleAltOutlinedIcon sx={{ fontSize: { xs: 60, sm: 80, md: 100 }, color: '#ffc107' }} /> 
        </Container>
    );
}

export default HowWeWorkComponent;
