import React from 'react';
import Slider from 'react-slick';
import { Box, Typography, Paper, Link, Avatar, useTheme } from '@mui/material';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import trustpilotLogo from '../assets/trust.png'; // Path to Trustpilot logo
import starsImage from '../assets/5stars.png'; // Path to 5-stars image

const testimonials = [
    {
        id: 1,
        name: "JMK Olly",
        date: "3 March 2022",
        content: "Excellent Service!! I was involved in a trading investment Scam with Excentral. When I wanted part of the money because it was a bank loan, my...",
        rating: 5,
        initials: "JO" // Initials for the profile image placeholder
    },
    {
        id: 2,
        name: "Antonetta Fernandes",
        date: "28 February 2022",
        content: "Superb experience all thru the process... Superb experience all thru the process after being scammed by an online trading platform. I found the staff are excellent an...",
        rating: 4.5,
        initials: "AF"
    },
    {
        id: 3,
        name: "Robert Gastman",
        date: "22 February 2022",
        content: "Story of Success Having written off a vast sum of money to an online crypto scam, I was contacted by Money Back in December 2019, and after...",
        rating: 4,
        initials: "RG"
    }
];

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 960,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};

const TestimonialsComponent = () => {
    const theme = useTheme();

    return (
        <Box sx={{
            padding: 2,
            maxWidth: '1200px',
            margin: 'auto',
            overflow: 'hidden'  // Ensuring no overflow
        }}>
            <Typography variant="h4" sx={{
                mb: 2,
                textAlign: 'center',
                color: theme.palette.primary.main,
                fontWeight: 'bold'
            }}>
                Testimonials
            </Typography>
            <Box sx={{
                textAlign: 'center',
                mb: 1
            }}>
                <img src={trustpilotLogo} alt="Trustpilot" style={{
                    margin: 'auto',
                    display: 'block',
                    maxWidth: '120px',
                    height: 'auto'
                }} />
                <Typography component="div" sx={{
                    color: theme.palette.text.secondary,
                    mt: 1
                }}>
                    Rated 4.5 / 5 based on 
                    <Link href="/#" underline="hover" sx={{
                        color: theme.palette.info.main,
                        ml: 0.5
                    }}>
                        1169 reviews
                    </Link>
                    . Showing our favorite reviews.
                </Typography>
            </Box>
            <Slider {...settings}>
                {testimonials.map(testimonial => (
                    <Box key={testimonial.id} sx={{
                        padding: 2,
                        maxWidth: '300px',
                        m: '0 auto' // Center the cards in the slider
                    }}>
                        <Paper elevation={3} sx={{
                            padding: 2,
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between'
                        }}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center'
                            }}>
                                <Avatar sx={{
                                    width: 36,
                                    height: 36,
                                    bgcolor: theme.palette.secondary.main,
                                    mr: 1
                                }}>
                                    {testimonial.initials}
                                </Avatar>
                                <Typography variant="subtitle1" sx={{
                                    fontWeight: 'bold',
                                    color: theme.palette.primary.main
                                }}>
                                    {testimonial.name}
                                </Typography>
                            </Box>
                            <Typography variant="body2" color="text.secondary" sx={{
                                fontSize: '0.8rem'
                            }}>
                                {testimonial.date}
                            </Typography>
                            <img src={starsImage} alt="Rating" style={{
                                width: '100px',
                                height: '20px'
                            }} />
                            <Typography variant="body2" sx={{
                                mb: 1
                            }}>
                                {testimonial.content}
                            </Typography>
                            <Typography variant="body2" sx={{
                                textDecoration: 'underline',
                                cursor: 'pointer',
                                color: theme.palette.info.main
                            }}>
                                Read on Trustpilot
                            </Typography>
                        </Paper>
                    </Box>
                ))}
            </Slider>
        </Box>
    );
};

export default TestimonialsComponent;
