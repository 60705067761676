import React from 'react';
import { Grid, Typography, Box, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import GavelIcon from '@mui/icons-material/Gavel';
import MoneyOffCsredIcon from '@mui/icons-material/MoneyOffCsred';
import './Works.css'

const WorkingComp = () => {
    const theme = useTheme(); // Using theme for styling

    const InteractiveBox = styled(Box)({
      transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
      '&:hover': {
        transform: 'translateY(-5px)',
        boxShadow: theme.shadows[10]
      },
      border: `2px solid ${theme.palette.primary.main}`,
      borderRadius: theme.shape.borderRadius,
      backgroundColor: '#fff',
      padding: theme.spacing(2),
      className: 'interactive-box' // Adding class for external CSS targeting
    });

    return (
        <Box sx={{ flexGrow: 1, padding: theme.spacing(4), maxWidth: '1200px', margin: 'auto', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <Typography variant="h4" gutterBottom component="div" textAlign="center" sx={{ marginBottom: theme.spacing(2) }}>
                How it works
            </Typography>
            <Grid container spacing={2} justifyContent="space-evenly">
                {[{ Icon: SearchIcon, title: "Review your case", description: "Performing preliminary checks to assess whether the case can result in a substantial recovery, based on our experience." },
                  { Icon: FactCheckIcon, title: "Gather the evidence", description: "Collecting all the information and documentation required to successfully pursue your case." },
                  { Icon: GavelIcon, title: "Confront the entities", description: "Systematically confronting the relevant entities that have facilitated the illicit transfer of your wealth." },
                  { Icon: MoneyOffCsredIcon, title: "Get your money back", description: "We take pride in our track record and assure you that we’ll go to great lengths to get your money back." }
                ].map((step, index) => (
                    <Grid item xs={12} sm={6} md={6} lg={6} key={index}>
                        <InteractiveBox>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <step.Icon className='icon' sx={{ fontSize: 40, marginRight: 2 }} />
                                <Typography variant="h6" component="h3">{step.title}</Typography>
                            </Box>
                            <Typography align="left">{step.description}</Typography>
                        </InteractiveBox>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
}

export default WorkingComp;
