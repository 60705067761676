import React from 'react';
import { Typography, Button, Box } from '@mui/material';
import { styled } from '@mui/material/styles';

// Custom styling for the button
const CustomButton = styled(Button)({
    backgroundColor: '#ffc107', // Yellow color for the button
    color: '#000', // Text color
    fontWeight: 'bold',
    '&:hover': {
        backgroundColor: '#b28900' // Darker yellow on hover
    }
});

const MoneyRetrievedComp = ({scrollToHomeMain }) => {
    return (
        <Box sx={{
            backgroundColor: '#112152', // Dark blue background
            padding: { xs: '20px 30px', sm: '30px 45px', md: '40px 60px' }, // Responsive padding
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' }, // Stack on small screens, row on medium and up
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '20vh', // Adjusted to minHeight for better flexibility
            color: '#ffffff'
        }}>
            <Box sx={{ maxWidth: '600px', mb: { xs: 4, md: 0 }, mr: { md: '20px' } }}>
                <Typography variant="h6" sx={{ fontSize: { xs: '1.25rem', sm: '1.4rem', md: '1.5rem' }, fontWeight: 'bold' }}>
                    Money retrieved in 2023 (Until Now)
                </Typography>
                <Typography sx={{ mt: 2, mb: 3, fontSize: { xs: '0.8rem', sm: '0.9rem', md: '1rem' } }}>
                    Our team of experts secures refunds on a daily basis. We assist businesses as well as individuals in their pursuit of funds lost to scammers. Our goal is to continually improve in our endeavors and we are always here to help you in your fight for what is rightfully yours.
                </Typography>
                <CustomButton  onClick={scrollToHomeMain} variant="contained" size="large">Get a free consultation</CustomButton>
            </Box>
            <Typography variant="h3" sx={{ fontSize: { xs: '2.5rem', sm: '3rem', md: '4rem' }, fontWeight: 'bold', color: '#ffc107' }}>
                $2,328,223
            </Typography>
        </Box>
    );
}

export default MoneyRetrievedComp;
