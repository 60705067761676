import React from 'react';
import { Grid, Typography, Link, Box, Divider } from '@mui/material';
import TrustpilotLogo from '../assets/tt.png'; // Import the Trustpilot logo image
import Stars from '../assets/5stars.png'; // Import the stars image

const FooterComp = () => {
    return (
        <Box sx={{ color: 'white', fontSize: '0.875rem' }}>
            {/* Main Footer Area */}
            <Box sx={{ backgroundColor: '#112152', padding: '40px 60px' }}>
                <Grid container spacing={5}>
                    {/* Column 1 */}
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h6" sx={{ color: '#FFF', mb: 2 }}>
                            Our office hours
                        </Typography>
                        <Typography>Mon-Fr: 8:00-19:00 GMT</Typography>
                        <Typography>Sat: 8:00-14:00 GMT</Typography>
                        <Typography>Sun: Closed</Typography>
                    </Grid>
                    
                    {/* Column 2 */}
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h6" sx={{ color: '#FFF', mb: 2 }}>
                            Contact us
                        </Typography>
                        <Typography>UK: +44 7451293596</Typography>
                        <Typography>CA: +1 6475609479</Typography>
                    </Grid>
                    
                    {/* Column 3 */}
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h6" sx={{ color: '#FFF', mb: 2 }}>
                            FINANCE ACTIVE UK LIMITED
                        </Typography>
                        <Typography>Company number 08510428</Typography>
                        <Typography>Registered office address 180 Great Portland Street, London, United Kingdom, W1W 5QZ</Typography>
                    </Grid>
                </Grid>
                <Divider sx={{ bgcolor: 'white', my: 4 }} />
                {/* Trustpilot Logo and Rating */}
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <img src={TrustpilotLogo} alt="Trustpilot" style={{ height: '48px', marginRight: '8px' }} />
                    <img src={Stars} alt="Rating Stars" style={{ height: '24px' }} />
                    <Typography sx={{ ml: 2, color: 'white' }}>
                        TrustScore 4.5 | 1169 Reviews
                    </Typography>
                </Box>
                <Typography variant="h6" sx={{ color: '#FFF', mb: 2, mt: 4 }}>
                    Social
                </Typography>
                <Link href="#" color="inherit" sx={{ pr: 2 }}>Facebook</Link>
                <Link href="#" color="inherit" sx={{ pr: 2 }}>Twitter</Link>
                <Link href="#" color="inherit">YouTube</Link>
            </Box>
            {/* Disclaimer Area */}
            <Box sx={{ backgroundColor: '#0D1A2B', padding: '20px 60px' }}>
                <Typography variant="caption" display="block" gutterBottom>
                    Disclaimer: CashRecoveryLTD offers each new client a free consultation. Funds Recovery or other services that will be subsequently commissioned will incur fees and/or commissions, based on the service and the complexity of each individual case. CashRecoveryLTD doesn't offer any investments, financial services, or advice.
                </Typography>
                <Typography variant="caption" display="block">
                    At CashRecoveryLTD we do not initiate calls without request. We only call clients that reached out to us. The Company cannot accept prohibited payment methods. Every payment received by the company is processed through the PCI-DSS protocol.
                </Typography>
                <Typography variant="caption" display="block">
                    © 2024 CashRecoveryLTD
                </Typography>
            </Box>
        </Box>
    );
}

export default FooterComp;
