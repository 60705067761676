import React from 'react';
import { Box, Button, Typography } from '@mui/material';

const GetMoneyBackComponent = ({scrollToHomeMain}) => {
    return (
        <Box sx={{
            backgroundColor: '#112152', // dark blue background
            color: 'white', // white text color
            padding: '40px 60px', // generous padding for visual appeal
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center', // center items vertically
            justifyContent: 'center', // center items horizontally
            minHeight: '300px', // minimum height for the component
            textAlign: 'center' // center align text
        }}>
            <Typography variant="h4" component="h1" gutterBottom>
                Let's get your money back!
            </Typography>
            <Typography variant="subtitle1" sx={{ mb: 3 }}>
                If you've been ripped off by scammers, get in touch and our team of experts will work to get your money back.
            </Typography>
            <Button onClick={scrollToHomeMain} variant="contained" sx={{ backgroundColor: '#ffc107', '&:hover': { backgroundColor: '#b28900' } }}>
                Get a free consultation
            </Button>
        </Box>
    );
}

export default GetMoneyBackComponent;
