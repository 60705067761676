import React, { useState } from 'react';
import { Typography, Accordion, AccordionSummary, AccordionDetails, Box, Divider } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const AccordionComponent = () => {
    // State to manage the currently open accordion panel
    const sections = [
        { 
            id: 'section1',
            title: "Learn more about CashRecovery LTD and the chargeback industry right here", content: (
            <span>
                Online scams are prevalent among illegitimate shops and merchants on the internet today. It is laden with untrustworthy transactions that seek to victimize those who are vulnerable. While we guard ourselves against these dangerous tradings, there are times when they cannot be prevented.<br /><br />
                For instance, there are a lot of online markets and traders that can easily fool people by posing as a legitimate authority. They can be so real that not a lot of people can spot their real motives. Falling victim to this kind of scam can be really frustrating and painful, not just in our credits but also in our integrity as a buyer or a trader. While we think that all hope is lost, it’s not quite over.<br /><br />
                CashRecoveryLTD’s funds’ recovery program has been helping consumers and users online recover what is rightfully theirs after being victimized by trading scams, trade platform scams, and the likes.<br /><br />
                Funds recovery has been a prominent method of wealth recovery and scam money recovery. Due to this, there are also recovery scams that have been spreading online. With all the deceptions and frauds circulating on the internet, you must be smart about it.<br /><br />
                For a legitimate wealth recovery expert, CashRecoveryLTD can help you. CashRecoveryLTD scam recovery has helped victims all over the world to retrieve their money with their wealth recovery experts on scams. For recovery funds in the UK, they are adept at the area. To learn more about them and the trade industry, here is everything you need to know.<br /><br />
            </span>
        )},
        {
            id: 'section2',
            title: "The chargeback industry",
            content: (
                <span>
                    First and foremost, a chargeback is characterized as the action or process of filing a request for retrieval of funds by the customer either with a bank. Naturally, when a consumer buys something, he/she will provide the monetary price of the item or service that is required for the business transaction. However, on rare occasions, any consumer can file for a return of funds from the shop or merchant, either due to error in processing, disagreements, or possible fraud.<br /><br />
                    The chargeback method is created in consideration of the customer’s safety and protection from scams or any fraudulent scams in the marketplace. There are consumer protection codes in some parts of the world that make sure of the return of products in the case of detection of a scam. Some shops require a tedious refund process to prevent consumers from asking for a refund since they would have to go through a lot of trouble just for repayment. Hence, the chargeback industry is useful in this account. Rather than the conventional means of asking for the money back, the chargeback method asks the bank directly for a refund rather than the shop or merchant itself. Chargebacks are designed to provide consumers with security when shopping online and ensure that sellers remain transparent and prevent them from selling bogus products or services.<br /><br />
                </span>
            )},
            {
                id: 'section3',
                title: "Funds recovery with CashRecoveryLTD",
                content: (
                    <span>
                        CashRecoveryLTD offers this kind of service with wealth recovery experts and fund recovery specialists – ensuring that you get your investments back from scammers. However, what really is funds recovery? In a nutshell, funds recovery is a service that is both consultative and investigative in its approach.<br /><br />
                        This is a service employed by victims that have been duped by online fraudulent crimes to retrieve their money from bogus merchants and trader scams. Funds recovery services like CashRecoveryLTD specialize in handling internet scams online and lend a hand in providing refunds and reimbursements to the targets through the use of arguments and disputes, along with digital fingerprints track downs, analysis of cyber transactions, and an in-depth investigation.<br /><br />
                        Companies that provide funds recovery services have a high chance of a successful crypto scam recovery, Bitcoin scam recovery, scam money recovery, and wealth recovery. When CashRecoveryLTD receives a complaint from victims, they immediately work and review the details of the situation. For a successful recovery, funds recovery review or scam recovery review of the claims must be done, and pieces of evidence must be gathered. The complaint must be valid, and the terms and conditions of the company must be followed.<br /><br />
                    </span>
                )
            },        { title: "Recover from online trading scams", content: "How can I recover my lost money from Forex? How to recover trading loss? These questions linger among those who have fallen target to online trading scams victims. Forex and Cryptocurrencies have shaped the trading markets quite differently. Nowadays, more and more people have been susceptible to these scams. However, with CashRecoveryLTD, getting your money back quickly is not a problem. Scams in online trading are connected to investment scams that are prevalent in the trading markets. They are often posted and advanced on social media sites, with fraud traders promising high returns along with popular endorsements from prominent people to encourage and attract people to trade and invest with them. They pose as professionals with legitimate-looking websites to trick people into their scams. CashRecoveryLTD provides solace for any online trading scams. The company helps people from trade platform scams and lets victims recover money from Bitcoin scam." },
            {
                id: 'section4',
                title: "About CashRecoveryLTD",
                content: (
                    <span>
                        CashRecoveryLTD only has one mission: to protect people from frauds and scams online worldwide, whatever it takes. We help victims of fraud online to recover what is rightfully theirs with their wealth recovery experts, scam money recovery professionals, and fund recovery specialists.<br /><br />
                        The company focuses on unregulated Forex and Binary Options Brokers and has already handled millions of victims around the globe with a high turnover. In July alone, we have recovered more than $300,000 funds and money from frauds. Our team is composed of skilled experts and professionals with magnificent long-time experience.<br /><br />
                        We help you avoid recovery scams, as we are legitimate field specialists with a great deal of knowledge and experience in dealing with the forex industry, banking internationally, and the law of cybercrime enforced on the internet.<br /><br />
                        As a fund recovery agency, CashRecoveryLTD helps people conduct safe online transactions and provide services in the area of refunds and cancellations, forex and binary options scams, and other types of online frauds. Where recovery scams are prevalent, CashRecoveryLTD is the safer choice.<br /><br />
                        We specialize in recovery funds in the UK and extends our services on: Online trading scams, Funds recovery, Trade platform scams, Wealth recovery expert, Crypto scam recovery, Recover scammed bitcoin, Scam money recovery.<br /><br />
                        About Our services: Valuation Advisory Services - Our valuation experts provide valuation services for financial reporting, tax, investment and risk management purposes.<br /><br />
                        Cyber Risk - We are the world’s #1 incident response provider. Merging complete response capabilities with frontline threat intelligence from over 3000 incidents handled per year and end-to-end expertise we protect, detect and respond against cyberattacks. For immediate assistance, contact us via our 24x7 cyber incident hotlines.<br /><br />
                        Compliance and Regulation - End-to-end governance, advisory and monitorship solutions to detect, mitigate and remediate security, legal, compliance and regulatory risk.<br /><br />
                        Corporate Finance and Restructuring - Our corporate finance and restructuring solutions span M&A advisory, restructuring and insolvency, debt advisory, strategic alternatives, transaction diligence and independent financial opinions.<br /><br />
                        FINANCE INNOVATIONS LIMITED & CashRecoveryLTD Financial Investigations recovery agent's Ltd acquired CashRecoveryLTD's in 2018 and unified under the CashRecoveryLTD's brand in 2020.<br /><br />
                        Digital Technology Solutions - Enriching our professional services, our integrated software platform helps clients discover, quantify and manage risk in the corporate and private capital market ecosystem.<br /><br />
                        Environmental, Social and Governance Advisory Services (ESG) - Advisory and technology solutions, including policies and procedures, screening and due diligence, disclosures and reporting, investigations, value creation, and monitoring. Funds recovery reviews, as well as scam recovery reviews, are available on Our website and other review sites.<br /><br />
                        Furthermore, we are a certified company of TrustPilot as a funds recovery dome. If you have been victimized, you have the right to recover what you have lost. With the chargeback industry in effect and the professionals of CashRecoveryLTD, get back what is yours.<br /><br />
                    </span>
                )
            }    ];

            const [expanded, setExpanded] = useState(false);

            const handleChange = (panel) => (event, isExpanded) => {
                setExpanded(isExpanded ? panel : null);
            };
        
            return (
                <Box sx={{
                    flexGrow: 1,
                    p: 4,
                    maxWidth: 960,
                    margin: 'auto',
                }}>
                    <Typography variant="h6" sx={{
                        marginBottom: 2,
                        textAlign: 'center',
                        fontWeight: 'bold',
                        color: '#003087'
                    }}>
                        More about CashRecoveryLTD and the chargeback industry
                    </Typography>
                    {sections.map((section, index) => (
                        <div key={section.id}>
                            <Accordion
                                expanded={expanded === section.id}
                                onChange={handleChange(section.id)}
                                sx={{
                                    marginBottom: 2,
                                    border: 'none',
                                    boxShadow: 'none',
                                    '&.Mui-expanded': {
                                        margin: 'auto',
                                    }
                                }}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: '#003087' }} />}>
                                    <Typography sx={{ fontWeight: 'medium', color: '#003087' }}>
                                        {section.title}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails sx={{
                                    padding: '0 16px 16px',
                                }}>
                                    <Typography sx={{ color: '#666' }}>
                                        {section.content}
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            {index !== sections.length - 1 && <Divider />}
                        </div>
                    ))}
                </Box>
            );
        };
        
        export default AccordionComponent;