import React, { useState, useEffect, useRef  } from 'react';
import NavbarPrime from './Components/Navbar/Navbar';
import HomePrimeMain from './Components/HomeMain/HomeMain';
import ServicesCOMP from './Components/Home/Services';
import WorkingCOmp from './Components/Works/Works';
import MoneyRetrievedComp from './Components/Retrieved/Retrieved';
import WhyWorkWithUs from './Components/ChooseUS/ChooseUS';
import GetMoneyBackCTA from './Components/GetMoneyback/Getmoneyback';
import TestimonialsComponent from './Components/Testi/Testi';
import HowWeWorkComponent from './Components/HowToDoit/HowtoDo';
import VideoReviewsComponent from './Components/VideoReviews/VideoReviews';
import ScamTypesComponent from './Components/TypesOf/Typesof';
import AccordionComponent from './Components/Faq/Faq';
import GetMoneyBackComponent from './Components/LetsGeturMoney/LetsGeturMoney';
import ArticleCarousel from './Components/Articles/Articles';
import ContactForm from './Components/ContactComp/ContactForm';
import Footer1Comp from './Components/Footer1/Footer1';
import FooterComp from './Components/Footer/Footer';
import { Box, Typography, LinearProgress } from '@mui/material';

import imfg from './Components/assets/imfg.png'

import axios from 'axios';


function App() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);
  const [defaultCountry, setDefaultCountry] = useState('US'); // Default to 'US'
  const [loading, setLoading] = useState(true);
  const homeMainRef = useRef(null); // Ref for scrolling to HomePrimeMain



  useEffect(() => {
    const fetchCountryCode = async () => {
      try {
        const { data } = await axios.get('https://ipinfo.io/json');
        setDefaultCountry(data.country); // Make sure country code is in the correct format
      } catch (error) {
        console.error('Error fetching country code:', error);
        setDefaultCountry('us'); // Fallback country code
      } finally {
        setLoading(false)
      }
    };

    fetchCountryCode();
  }, []);


  const scrollToHomeMain = () => {
    if (homeMainRef.current) {
      homeMainRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  if (loading) {
    return    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
      bgcolor: '#112152'
    }}>
      <img src={imfg} alt="Logo" style={{ maxWidth: '90%', height: 'auto' }}/>
      <Typography variant="h4" sx={{ color: '#ffffff', mb: 3 }}>
      </Typography>
      <LinearProgress sx={{
        width: '80%',
        '& .MuiLinearProgress-bar': {
          backgroundColor: '#ffc107'  // Custom color for the progress bar
        }
      }} />
    </Box>;
  }
  


  return (
    <div className="App">
      <NavbarPrime />
      <HomePrimeMain ref={homeMainRef} countryCode={defaultCountry} />
     <ServicesCOMP />
     <WorkingCOmp />
     <MoneyRetrievedComp scrollToHomeMain={scrollToHomeMain}/>
     <WhyWorkWithUs scrollToHomeMain={scrollToHomeMain}/>
     <GetMoneyBackCTA scrollToHomeMain={scrollToHomeMain}/>
     <TestimonialsComponent/>
     <HowWeWorkComponent scrollToHomeMain={scrollToHomeMain}/>
     <VideoReviewsComponent/>
     <ScamTypesComponent/>
     <AccordionComponent/>
     <GetMoneyBackComponent scrollToHomeMain={scrollToHomeMain}/>
     <ArticleCarousel/>
      <FooterComp />
    </div>
  );
}

export default App;
