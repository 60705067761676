import React from 'react';
import { Typography, Button, Container } from '@mui/material';
import { styled } from '@mui/material/styles';

// Custom styling for the button to maintain consistency with the design
const CustomButton = styled(Button)({
    backgroundColor: '#ffc107', // Bright yellow color for the button
    color: '#000', // Text color is black for contrast
    fontWeight: 'bold',
    padding: '10px 30px', // Larger padding for a bigger button
    '&:hover': {
        backgroundColor: '#b28900' // Darker yellow on hover
    }
});

const GetMoneyBackCTA = ({scrollToHomeMain}) => {
    return (
        <Container maxWidth="xxl" sx={{
            backgroundColor: '#112152', // Dark blue background
            color: '#ffffff', // Text color is white for contrast
            padding: { xs: '20px 30px', sm: '30px 45px', md: '40px 60px' }, // Responsive padding
            display: 'flex',
            flexDirection: 'column', // Stack elements vertically
            alignItems: 'center', // Center align items
            justifyContent: 'center', // Center justify content
            minHeight: '200px', // Use minHeight for flexibility
        }}>
            <Typography variant="h4" component="h1" sx={{
                fontWeight: 'bold',
                textAlign: 'center',
                mb: 2,
                fontSize: { xs: '1.25rem', sm: '1.5rem', md: '1.75rem', lg: '2rem' } // Responsive font size
            }}>
                Let's get your money back!
            </Typography>
            <Typography variant="subtitle1" sx={{
                textAlign: 'center',
                mb: 3,
                fontSize: { xs: '0.75rem', sm: '0.875rem', md: '1rem' } // Responsive font size for subtitles
            }}>
                If you've been ripped off by scammers, get in touch and our team of experts will work to get your money back.
            </Typography>
            <CustomButton  onClick={scrollToHomeMain}  variant="contained" sx={{ fontSize: { xs: '0.8rem', sm: '0.9rem', md: '1rem' } }}>
                Get a free consultation
            </CustomButton>
        </Container>
    );
}

export default GetMoneyBackCTA;
