import React from 'react';
import { Typography, Grid, Box, Paper } from '@mui/material';
import CasinoIcon from '@mui/icons-material/Casino'; // Example icon for Binary options
import CurrencyBitcoinIcon from '@mui/icons-material/CurrencyBitcoin'; // For Cryptocurrency
import ShowChartIcon from '@mui/icons-material/ShowChart'; // For Forex
import FavoriteIcon from '@mui/icons-material/Favorite'; // For Romance
import HouseIcon from '@mui/icons-material/House'; // For Property scams
import TrendingUpIcon from '@mui/icons-material/TrendingUp'; // For Stock trading
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'; // For Other scams

const ScamTypesComponent = () => {
    const scams = [
        { icon: CasinoIcon, name: 'Binary options' },
        { icon: CurrencyBitcoinIcon, name: 'Crypto' },
        { icon: ShowChartIcon, name: 'Forex' },
        { icon: FavoriteIcon, name: 'Romance' },
        { icon: HouseIcon, name: 'Property scams' },
        { icon: MoreHorizIcon, name: 'Credit card phishing' },
        { icon: TrendingUpIcon, name: 'Stock trading' },
        { icon: MoreHorizIcon, name: 'Other scams' }
    ];

    return (
        <Box sx={{ flexGrow: 1, p: 4, maxWidth: 960, margin: 'auto' }}>
            <Typography variant="h4" sx={{ mb: 4, textAlign: 'center' }}>
                The types of scams we can help you with
            </Typography>
            <Grid container spacing={1} justifyContent="space-around"> {/* Adjusted justifyContent to 'space-around' */}
                {scams.map((scam, index) => (
                    <Grid item xs={6} sm={3} key={index} sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Paper elevation={3} sx={{
                            p: 2,
                            textAlign: 'center',
                            width: 180, // Adjusted width to be specific
                            margin: '0 10px' // Added margins to control the gap between papers
                        }}>
                            <scam.icon sx={{ fontSize: 40, mb: 1 }} />
                            <Typography variant="subtitle1">{scam.name}</Typography>
                        </Paper>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
}

export default ScamTypesComponent;
