import React from 'react';
import Slider from 'react-slick';
import { Box, Typography, Paper, IconButton } from '@mui/material';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const videoReviews = [
    { id: 1, image: require('../assets/review/review-1.jpg'), alt: 'Video Review 1' },
    { id: 2, image: require('../assets/review/review-2.jpg'), alt: 'Video Review 2' },
    { id: 3, image: require('../assets/review/review-3.jpg'), alt: 'Video Review 3' },
    { id: 4, image: require('../assets/review/review-4.jpg'), alt: 'Video Review 4' },
    { id: 5, image: require('../assets/review/review-5.jpg'), alt: 'Video Review 5' },
    { id: 6, image: require('../assets/review/review-6.jpg'), alt: 'Video Review 6' }
];

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
        {
            breakpoint: 960,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};

const VideoReviewsComponent = () => {
    return (
        <Box sx={{ padding: 4, textAlign: 'center' }}>
            <Typography variant="h4" sx={{ mb: 4 }}>
                Video Reviews
            </Typography>
            <Slider {...settings}>
                {videoReviews.map(review => (
                    <Box key={review.id} sx={{ padding: 2, margin: '0 10px', maxWidth: 'calc(100% - 30px)' }}> {/* Adjusted the margin and padding */}
                        <Paper elevation={3} sx={{
                            position: 'relative',
                            width: '80%',
                            height: 300,
                            backgroundImage: `url(${review.image})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            filter: 'brightness(50%)'
                        }}>
                            <IconButton
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    color: '#fff',
                                    fontSize: '3rem'
                                }}
                                aria-label="play video"
                            >
                                <PlayCircleOutlineIcon sx={{ fontSize: '3rem' }} />
                            </IconButton>
                        </Paper>
                    </Box>
                ))}
            </Slider>
        </Box>
    );
}

export default VideoReviewsComponent;
