import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Services.css'
import logo1 from '../assets/logos/logo-1.png';
import logo2 from '../assets/logos/logo-2.png';
import logo3 from '../assets/logos/logo-3.png';
import logo4 from '../assets/logos/logo-4.png';
import logo5 from '../assets/logos/logo-5.png';
import logo6 from '../assets/logos/logo-6.png';
import logo7 from '../assets/logos/logo-7.png';

const ServicesCOMP = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 3,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            }
        ]
    };

    return (
        <Box className='services-component'>
            <Container maxWidth="lg">
                <Typography variant="h6" component="h2" sx={{
                    textAlign: 'center',
                    mt: 3, mb: 3,
                    fontWeight: 'bold',
                    color: 'text.primary'
                }}>
                    Scams we helped our clients recover from all over the world
                </Typography>
                <Slider {...settings}>
                    {[
                        logo1, logo2, logo3, logo4, logo5, logo6, logo7
                    ].map((logo, index) => (
                        <div key={index}>
                            <img src={logo} alt={`Logo ${index + 1}`} style={{ width: '70%', height: 'auto' }} />
                        </div>
                    ))}
                </Slider>
            </Container>
        </Box>
    );
};

export default ServicesCOMP;
