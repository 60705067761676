import React from 'react';
import Slider from 'react-slick';
import { Box, Card, CardMedia, CardContent, Typography } from '@mui/material';

import Climb from '../assets/articles/climb.png';  // Adjust the path as necessary
import ebany from '../assets/articles/ebay.png';  // Adjust the path as necessary
import mask2 from '../assets/articles/mask2.png';  // Adjust the path as necessary
import vector from '../assets/articles/vector.png';  // Adjust the path as necessary
import diy from '../assets/articles/diy.png';  // Adjust the path as necessary
import ffs from '../assets/articles/image-1.png';  // Adjust the path as necessary
import btc from '../assets/articles/mask.png';  // Adjust the path as necessary
import robo from '../assets/articles/robo.png';  // Adjust the path as necessary
// Other images



const ArticleCarousel = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const articles = [
        {
            img: vector,
            date: 'April 26, 2022',
            title: 'What Is Forex Trading and How Does It Work?'
        },
        {
            img: Climb,
            date: 'April 25, 2022',
            title: 'Is the Stock Market Rigged?'
        },
        {
            img: ebany,
            date: 'April 25, 2022',
            title: 'eBay Motors Scam: How To Spot and Get Your Money Back'
        },
        {
            img: robo,
            date: 'April 24, 2022',
            title: 'Binary Options Robot Scams: How to Avod and Recover From the Bots'
        },
        {
            img: diy,
            date: 'April 24, 2022',
            title: 'Guide to DIY Chargebacks'
        },
        {
            img: mask2,
            date: 'April 24, 2022',
            title: 'How does the stock marker Work: Guide to beginners'
        },
        {
            img: ffs,
            date: 'April 24, 2022',
            title: 'What(And What Not) To Look Out For: Forex Trading Scams'
        },
        {
            img: btc,
            date: 'April 23, 2022',
            title: 'Bitcoin Mining Scams: Spotting and Recovering From Them'
        }
    ];

    return (
        <Box sx={{
            padding: '20px',
            maxWidth: 960,
            mx: 'auto', // Centering the Box
            overflow: 'hidden', // Prevents any overflow from the Slider
            '.slick-slide': { padding: '0 10px' },
            '.slick-list': { margin: '0 -10px' },
            '.slick-dots': { bottom: '10px', position: 'absolute' }
        }}>
            <Typography variant="h5" sx={{ mb: 3, textAlign: 'center' }}>
                Latest articles
            </Typography>
            <Slider {...settings}>
                {articles.map((article, index) => (
                    <Card key={index} sx={{
                        boxShadow: 'none',
                        border: '1px solid #ccc',
                        display: 'flex',
                        flexDirection: 'column',
                        '&:focus': {
                            outline: 'none'
                        }
                    }}>
                        <CardMedia
                            component="img"
                            height="140"
                            image={article.img}
                            alt="Article Image"
                        />
                        <CardContent sx={{
                            flexGrow: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between'
                        }}>
                            <Typography variant="body2" color="textSecondary">
                                {article.date}
                            </Typography>
                            <Typography variant="h6" sx={{
                                mt: 1,
                                height: '3em', // Ensuring consistent height for titles
                                overflow: 'hidden', // Handles text overflow
                                display: '-webkit-box',
                                WebkitLineClamp: 2,
                                WebkitBoxOrient: 'vertical',
                            }}>
                                {article.title}
                            </Typography>
                        </CardContent>
                    </Card>
                ))}
            </Slider>
        </Box>
    );
};

export default ArticleCarousel;
