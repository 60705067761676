import React from 'react';
import './Navbar.css';  // Import the CSS file for styling

const NavbarPrime = () => {
    return (
        <nav className="navbar">
            <ul className="nav-links">
                <li><a href="/">CashRecoverLTD</a></li>
               
                <li><a id='getitback' href="/contact">GET YOUR MONEY BACK!</a></li>
            </ul>
        </nav>
    );
};

export default NavbarPrime;
