import React from 'react';
import { Typography, Button, Box, List, ListItem, ListItemIcon, ListItemText, Container } from '@mui/material';
import { styled } from '@mui/material/styles';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import GuaranteeBadge from '../assets/moneyback.png';

// Custom styling for buttons and other elements
const CustomButton = styled(Button)({
  backgroundColor: '#ffc107',
  color: '#000',
  fontWeight: 'bold',
  padding: '8px 24px',
  '&:hover': {
    backgroundColor: '#b28900'
  }
});

const CustomOutlineButton = styled(Button)({
  borderColor: '#003087',
  color: '#003087',
  fontWeight: 'bold',
  padding: '8px 24px',
  '&:hover': {
    backgroundColor: '#f0f0f0'
  }
});

const MoneyRetrievedComp = ({scrollToHomeMain}) => {
  return (
    <Container maxWidth="md" sx={{
      backgroundColor: '#ffffff',
      padding: { xs: 2, sm: 3, md: 4 },
      marginTop: 4,
     
    }}>
      <Typography variant="h4" textAlign="center" sx={{
        color: '#003087',
        fontWeight: 'bold',
        marginBottom: 2,
        fontSize: { xs: '1.5rem', sm: '1.75rem', md: '2rem' }
      }}>
        Why Work with Us?
      </Typography>
      <Typography variant="body1" paragraph sx={{
        color: '#333',
        fontSize: { xs: '0.875rem', sm: '0.95rem', md: '1rem' }
      }}>
        We liaise with agencies involved in fraud investigations across the European Union, maintaining strong relationships with:
      </Typography>
      <List sx={{ pl: { xs: 1, sm: 2, md: 3 } }}>
        {[
          "Department for Work and Pensions (DWP)",
          "Department of International Trade (DIT)",
          "Federation Against Copyright Theft",
          "Financial Conduct Authority (FCA)",
          "HM Revenue and Customs (HMRC)",
          "Health and Safety Executive (HSE)",
          "National Crime Agency (NCA)",
          "Office of Fair Trading (OFT)",
          "Serious Fraud Office (SFO)",
          "Trading Standards"
        ].map((agency, index) => (
          <ListItem key={index} sx={{ py: 0.5 }}>
            <ListItemIcon>
              <BusinessCenterIcon sx={{ color: '#003087' }} />
            </ListItemIcon>
            <ListItemText primary={agency} primaryTypographyProps={{ style: { fontWeight: 'bold', fontSize: '0.875rem' } }} />
          </ListItem>
        ))}
      </List>
      <Typography variant="body1" paragraph sx={{ color: '#333', fontWeight: 'bold', fontSize: { xs: '0.875rem', sm: '0.95rem', md: '1rem' } }}>
        Our reputation for excellence is recognized throughout the EU. Our team has significant expertise in case building based on a sound knowledge of the criminal justice system, with the ability to assemble compelling evidence and documentation in recovery room fraud cases.
      </Typography>
      <Typography variant="body1" paragraph sx={{ color: '#333', fontWeight: 'bold', fontSize: { xs: '0.875rem', sm: '0.95rem', md: '1rem' } }}>
        We are capable of representing clients at all levels of criminal proceedings, from police stations to the Court of Appeal, including managing serious fraud cases with our highly experienced Higher Court Advocates.
      </Typography>
      <CustomButton  onClick={scrollToHomeMain} variant="contained" size="large">Get a free consultation</CustomButton>
      <Container maxWidth="md" sx={{
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'row' },
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: 4,
        padding: { xs: 2, sm: 4 },
        backgroundColor: '#f0f0f0'
      }}>
        <Box sx={{ flexGrow: 1, marginRight: { xs: 0, sm: 3 }, marginBottom: { xs: 2, sm: 0 } }}>
          <Typography variant="h5" component="h2" sx={{
            fontWeight: 'bold',
            marginBottom: 2,
            fontSize: { xs: '1.25rem', sm: '1.5rem', md: '1.75rem' }
          }}>
            Your money back guarantee
          </Typography>
          <Typography variant="body1" sx={{
            marginBottom: 2,
            fontSize: { xs: '0.875rem', sm: '0.95rem', md: '1rem' }
          }}>
            The fund recovery process can be a lengthy one and requires perseverance. Therefore, it is vital that our clients are ready for it and trust us every step of the way. So if for any reason you are doubtful, you can ask for a full refund within the first 14 business days of the process.
          </Typography>
          <CustomOutlineButton variant="outlined">Read Terms & Conditions</CustomOutlineButton>
        </Box>
        <Box sx={{ width: { xs: '100%', sm: 'auto' }, textAlign: { xs: 'center', sm: 'right' } }}>
          <img src={GuaranteeBadge} alt="100% Money Back Guarantee" style={{ width: { xs: 100, sm: 120 }, height: { xs: 100, sm: 120 } }} />
        </Box>
      </Container>
    </Container>
  );
}

export default MoneyRetrievedComp;
